var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c(
        "b-dropdown",
        {
          key: _vm.filterKey,
          staticClass: "filter-dropdown",
          attrs: {
            id: _vm.filterId,
            size: "sm",
            "toggle-class": _vm.filterClass,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "button-content",
                fn: function () {
                  return [
                    _vm._v(
                      "\n    " +
                        _vm._s(_vm.$t("FILTER." + _vm.filterName + ".TITLE")) +
                        "\n    "
                    ),
                    _vm.items.length != 0
                      ? _c("span", [
                          _vm._v("(" + _vm._s(_vm.items.length) + ")"),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            573518356
          ),
        },
        [
          _vm._l(_vm.amount, function (ix) {
            return _c(
              "b-dropdown-form",
              { key: "filter_" + _vm.filterName + "_" + ix },
              [
                _c(
                  "b-form-checkbox",
                  {
                    attrs: {
                      switch: "",
                      size: "sm",
                      name: "check-button",
                      value: ix - 1 + "",
                    },
                    model: {
                      value: _vm.items,
                      callback: function ($$v) {
                        _vm.items = $$v
                      },
                      expression: "items",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("FILTER." + _vm.filterName + "." + (ix - 1))
                      )
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          _vm.amount != 0 ? _c("b-dropdown-divider") : _vm._e(),
          _vm.amount.length != 0
            ? _c("b-dropdown-item", { on: { click: _vm.clearFilter } }, [
                _vm._v(_vm._s(_vm.$t("FILTER.CLEAR_SELECTED"))),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }