<template>
    <b-dropdown :key="filterKey" v-if="value"
      :id="filterId"
      class="filter-dropdown"
      size="sm"
      :toggle-class="filterClass"
    >
      <template #button-content>
        {{ $t("FILTER." + filterName + ".TITLE") }}
        <span v-if="items.length != 0">({{ items.length }})</span>
      </template>
      <b-dropdown-form
        v-for="ix in amount"
        :key="'filter_' + filterName + '_' + ix"
      >
        <b-form-checkbox
          switch
          size="sm"
          v-model="items"
          name="check-button"
          :value="(ix-1) + ''"
          >{{ $t("FILTER." + filterName + "." + (ix-1)) }}</b-form-checkbox
        >
      </b-dropdown-form>
      <b-dropdown-divider v-if="amount != 0"></b-dropdown-divider>
      <b-dropdown-item v-if="amount.length != 0" @click="clearFilter">{{
        $t("FILTER.CLEAR_SELECTED")
      }}</b-dropdown-item>
    </b-dropdown>
</template>
<script>
export default {
  props: {
    value: Array,
    amount: {
      default: 0,
      type: Number,
    },
    filterName: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      items: this.value
    };
  },
  methods: {
    clearFilter() {
      this.items = [];
    },
  },
  computed: {
    filterKey(){
        return "filterKey_" + this.filterName + "_" + this.value.length;
    },
    filterId() {
      return "filterId_" + this.filterName;
    },
    filterClass() {
      if (this.value.length != 0) {
        return ["filter-selected", "no-border"];
      } else {
        return ["no-border"];
      }
    },
  },
  watch: {
    value(){
      this.items = this.value;
    },
    items() {
      this.$emit("input", this.items);
    },
  },
  mounted() {
  },
};
</script>
<style>
</style>